/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TextInput, Dropdown } from '@carbon/ibm-security';
import React from 'react';
import { EMessages, getStates, stateMapper, RegexFormat } from 'utils';
import { CustomTextInput } from 'components/CustomTextInput';
import CustomerContext from '../CustomerContext';
import { EMode } from '../interface';
import { ERoles } from 'modules/Manage/ManageInterfaces';
import { AuthContext } from 'components/Auth';

interface ILocationProps {
	handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const classPrefix = 'sc--location'
const Location: React.FunctionComponent<ILocationProps> = (props: ILocationProps) => {
	const AuthCTX = React.useContext(AuthContext);

	const { handleInput } = props;
	const { state, dispatch } = React.useContext(CustomerContext);
	const [ postcodeValid, setPostCodeValidity ] = React.useState<boolean>(false);


	const validatePostcode = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const numberRegex = (RegexFormat.postCode);
		setPostCodeValidity(!numberRegex.test(event.target.value))
	}, [ setPostCodeValidity ]);


	const handleDropdownInitialSelectedItem = (dropDownArray: { id: string; text: string }[], initialSelectedValue) => {
		const initialSelecteditem = dropDownArray.find((obj) => {
			return obj.text === initialSelectedValue;
		});
		return initialSelecteditem;
	};

	const handleStateInput = React.useCallback(({ selectedItem }) => {

		dispatch({
			type: 'customer',
			value: { state: selectedItem.id },
			isNested: true
		})

	}, [ dispatch ]);

	const handleAddressInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({
			type: 'customer',
			value: { [ event.target.name ]: event.target.value },
			isNested: true
		})

	}, [ dispatch ])

	const { addressLine1, city, reference } = state.inputValidation.location;


	return (
		<div className='sc--createflow-step details'>
			<div className='sc--createflow-column'>
				<div className={`${classPrefix}-title title`}>Address information</div>
				<TextInput
					data-attribute='nested'
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Address line 1 *',
						id: `${state.mode.toLowerCase()}--customer-address-line-one`,
						name: 'addressLine1',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: addressLine1 && addressLine1.inValid,
						invalidText: EMessages.REQUIRED,
						value: state.customer.address.addressLine1,
						required: true
					}} />
				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Address line 2 (optional)',
						id: `${state.mode.toLowerCase()}--customer-address-line-two`,
						name: 'addressLine2',
						onChange: handleAddressInput,
						value: state.customer.address.addressLine2
					}} />
				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'City *',
						id: `${state.mode.toLowerCase()}--city`,
						name: 'city',
						onChange: handleAddressInput,
						invalid: city && city.inValid,
						invalidText: EMessages.REQUIRED,
						value: state.customer.address.city,
						required: true
					}} />
				<div className='sc--details-postcode-state-wrapper'>
					<Dropdown
						disabled={AuthCTX.claims.role !== ERoles.MANAGER}
						id={`${classPrefix}--customer-address-state`}
						ariaLabel={'state drop down filter'}
						items={getStates}
						itemToString={(item) => (item ? item.text : '')}
						initialSelectedItem={handleDropdownInitialSelectedItem(
							getStates,
							stateMapper(state.customer.address.state)
						)}
						label='Select state'
						onChange={(item) => handleStateInput(item)}
						titleText='State *'
					/>
					<CustomTextInput
						{...{
							disabled: AuthCTX.claims.role !== ERoles.MANAGER,
							id: `${state.mode.toLowerCase()}--customer-contact-phone-number`,
							labelText: 'Postcode *',
							format: '####',
							name: 'postcode',
							onBlur: validatePostcode,
							value: state.customer.address.postcode,
							invalid: postcodeValid,
							invalidText: EMessages.REQUIRED,
							onChange: handleAddressInput,
							required: true
						}} />
				</div>
			</div>
			<div className='sc--createflow-column'>
				<div className={`${classPrefix}-title title`}>Additional information</div>
				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						id: `${classPrefix}--customer-reference`,
						labelText: 'Reference *',
						name: 'reference',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: reference && reference.invalid,
						invalidText: EMessages.REQUIRED,
						value: state.customer.reference,
						required: true
					}}
				/>
			</div>
		</div>
	);
};

export { Location };
