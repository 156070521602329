/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { FirebaseContext } from "components/Firebase";
import { AuthContext } from "components/Auth";
import CustomerContext from "./CustomerContext";
import { CustomerReducer } from "./CustomerReducer";
import { CustomersTable } from "./CustomersTable";
import {
  ICustomerLocation,
  ICustomerInitialState,
  ICustomerContextProps,
  ICustomerReference,
} from "./interface";
import { CustomerAddEdit } from "./CustomerAddEdit";
import { handleTableSearch } from "utils/table";

const initialState: ICustomerInitialState = {
  addEditViewEnabled: false,
  customers: [],
  customer: {} as ICustomerLocation,
  mode: "",
  tableDataLoaded: false,
  inputValidation: { details: {}, location: {} },
};

const CustomerContainer: React.FunctionComponent = () => {
  const AuthCTX = React.useContext(AuthContext);
  const FirebaseCTX = React.useContext(FirebaseContext);
  const [state, dispatch] = React.useReducer(CustomerReducer, initialState);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [customerId, setCustomerId] = React.useState<string>("");
  const [customerRef, setCustomerRef] = React.useState<ICustomerReference>(
    {} as ICustomerReference
  );

  const getCustomerLocations = React.useCallback(() => {
    const customersArray = [] as any;
    setIsLoading(true);
    if (AuthCTX.claims && AuthCTX.claims.customerRef) {
      FirebaseCTX &&
        FirebaseCTX.customerLocations()
          .where("customer.id", "==", `${AuthCTX.claims.customerRef}`)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const tempObj = Object.assign(doc.data(), { id: doc.id });
              customersArray.push(tempObj);
            });
            dispatch({
              type: "customers",
              value: customersArray,
            });
          });
    }
  }, [setIsLoading, FirebaseCTX, AuthCTX]);

  const getCustomerReference = React.useCallback((): any => {
    setIsLoading(true);
    if (FirebaseCTX) {
      const customerReference = FirebaseCTX.customers().doc(
        AuthCTX.claims.customerRef
      );
      customerReference.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            const customer: ICustomerReference = {
              id: doc.id,
              logoUrl: data.logo,
              name: data.companyName,
              paymentEmail: data.paymentEmail,
            };
            setCustomerRef(customer);
          }
        }
      });
    }
  }, [FirebaseCTX, AuthCTX, setCustomerRef]);

  const initialLoad = React.useCallback(() => {
    const getCustomerLocationPromise = getCustomerLocations();
    const getCustomerPromise = getCustomerReference();

    const promises = [getCustomerLocationPromise, getCustomerPromise];

    Promise.all(promises).then(() => {
      setIsLoading(false);
    });
  }, [setIsLoading, dispatch, AuthCTX.claims]);

  const updateCustomerLocation = React.useCallback(() => {
    const tempCustomerLocation: ICustomerLocation = Object.assign(
      {},
      state.customer
    );
    delete tempCustomerLocation.id;
    tempCustomerLocation.address.country = "Australia";

    // If customer locations service details fields are empty assign
    // the contacts details as the service owner details
    if (tempCustomerLocation.serviceContactEmail === "") {
      tempCustomerLocation.serviceContactEmail = state.customer.contactEmail;
    }
    if (tempCustomerLocation.serviceContactName === "") {
      tempCustomerLocation.serviceContactName = state.customer.contactName;
    }
    if (tempCustomerLocation.serviceContactPhone === "") {
      tempCustomerLocation.serviceContactPhone =
        state.customer.contactPhoneNumber;
    }

    tempCustomerLocation.customer = customerRef;
    tempCustomerLocation.handle_by = "customer";
    console.log("tempCustomerLocation:: :: ", tempCustomerLocation);
    // return;
    setIsLoading(true);
    FirebaseCTX &&
      FirebaseCTX.customerLocations()
        .doc(customerId)
        .update(tempCustomerLocation)
        .then(() => {
          dispatch({
            type: "addEditViewEnabled",
            value: {
              addEditViewEnabled: false,
              mode: "",
            },
          });
          initialLoad();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [state, setIsLoading, customerId, dispatch, customerRef]);

  React.useEffect(() => {
    const abortController = new AbortController();
    //TODO: make sure when component unmounts to remove these classes not on mounting of
    //other classes.
    document.body.classList.remove(
      "create-flow-open",
      "bx--body--with-modal-open"
    );

    if (AuthCTX.claims) {
      initialLoad();
    }
    return () => abortController.abort();
  }, [AuthCTX]);

  const createNewCustomerLocation = React.useCallback(async () => {
    const tempCustomerLocation: ICustomerLocation = Object.assign(
      {},
      state.customer
    );

    delete tempCustomerLocation.id;
    tempCustomerLocation.address.country = "Australia";

    // If customer locations service details fields are empty assign
    // the contacts details as the service owner details
    if (tempCustomerLocation.serviceContactEmail === "") {
      tempCustomerLocation.serviceContactEmail = state.customer.contactEmail;
    }
    if (tempCustomerLocation.serviceContactName === "") {
      tempCustomerLocation.serviceContactName = state.customer.contactName;
    }
    if (tempCustomerLocation.serviceContactPhone === "") {
      tempCustomerLocation.serviceContactPhone =
        state.customer.contactPhoneNumber;
    }

    tempCustomerLocation.customer = customerRef;
    tempCustomerLocation.handle_by = "customer";
    setIsLoading(true);
    FirebaseCTX &&
      FirebaseCTX.customerLocations()
        .add(tempCustomerLocation)
        .then(() => {
          initialLoad();
          dispatch({
            type: "addEditViewEnabled",
            value: {
              addEditViewEnabled: false,
              mode: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [state, setIsLoading, dispatch, customerRef]);

  const handleCustomerId = React.useCallback(
    (customerId: string) => {
      setCustomerId(customerId);
    },
    [setCustomerId]
  );

  const handleCustomerLocationSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;

      handleTableSearch(searchValue, state.customers, "contactName")
        .then((filteredResults: any) => {
          if (filteredResults.length) {
            dispatch({
              type: "customers",
              value: filteredResults,
            });
          } else {
            initialLoad();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [state, dispatch]
  );

  const customerContextProps = {
    createNewCustomerLocation,
    updateCustomerLocation,
    handleCustomerId,
    handleCustomerLocationSearch,
    isLoading,
  };

  const contextValue: ICustomerContextProps = React.useMemo(() => {
    return {
      ...customerContextProps,
      state,
      dispatch,
    };
  }, [state, dispatch, customerContextProps]);

  return (
    <div className="bx--grid">
      <CustomerContext.Provider value={contextValue}>
        <div className={"sc--main-title"}>
          <h1>Service Locations</h1>
        </div>
        {state.addEditViewEnabled && <CustomerAddEdit />}
        <CustomersTable />
      </CustomerContext.Provider>
    </div>
  );
};

export { CustomerContainer };
