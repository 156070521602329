const ManageReducer = (prevState, action) => {

	switch (action.type) {
		case 'manageCustomerUsers':
			return {
				...prevState,
				tableDataLoaded: true,
				manageCustomerUsers: [ ...action.value ]
			}
		case 'editableCustomerUser':
			return {
				...prevState,
				manageCustomerUser: {
					...prevState.manageCustomerUser,
					...action.value
				}
			}
		case 'closeCustomerUserFlow':
			return {
				...prevState,
				addEditViewEnabled: false,
				manageCustomerUser: {}
			}
			case 'addEditViewEnabled':
			return {
				...prevState,
				addEditViewEnabled: action.value.addEditViewEnabled,
				mode: action.value.mode
			}
		default:
			return {
				...prevState
			}
	}
}

export {
	ManageReducer
}