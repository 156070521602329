
/* eslint-disable no-useless-escape */
import { TextArea, TextInput } from '@carbon/ibm-security';
import React from 'react';
import RequestsContext from '../RequestsContext';
import { ConvertTime } from 'utils';
import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';
import { IRequestDate } from '../RequestInterfaces';

const stepPrefix = 'sc--details';
const classPrefix = 'sc--request-flow';

const DetailsStep: React.FunctionComponent = () => {
	const Context = React.useContext(RequestsContext);
	const [ imageData, setImageData ] = React.useState([]);

	React.useEffect(() => {
		if (Context.state.customerRequest.requestedImagePaths) {
			const images = Context.state.customerRequest.requestedImagePaths.map((item: string, index: number) => {
				return {
					src: item,
					title: `Image ${index + 1}`,
					description: Context.state.customerRequest.description
				}
			});
			setImageData(images)
		}
	}, [ setImageData ]);

	return (

		<div className={`${classPrefix}-step details`}>
			<div className={`${classPrefix}-column`}>
				<div className={`${stepPrefix}-title title`}>Maintenance information</div>
				<TextInput
					{...{
						className: `${classPrefix}-input`,
						labelText: 'Identifier',
						id: 'identifier',
						name: 'identifier',
						value: Context.state.customerRequest.docId,
						disabled: true
					}}
				/>
				<TextInput
					{...{
						className: `${classPrefix}-input`,
						labelText: 'Reference',
						id: 'store-identifier',
						name: 'reference',
						value: Context.state.customerRequest.customerLocation.reference,
						disabled: true
					}}
				/>

				<TextInput
					{...{
						className: `${classPrefix}-input`,
						labelText: 'Type of contractor required',
						id: 'service-type',
						name: 'serviceType',
						value: Context.state.customerRequest.serviceType,
						disabled: true
					}}
				/>

				<TextInput
					{...{
						className: `${classPrefix}-input`,
						labelText: 'Request date',
						id: 'request-date',
						name: 'requestStartDate',
						value: ConvertTime(Context.state.customerRequest.requestStartDate as IRequestDate),
						disabled: true
					}}
				/>

				<TextInput
					{...{
						className: `${classPrefix}-input`,
						labelText: 'Status',
						id: 'status',
						name: 'status',
						value: Context.state.customerRequest.status,
						disabled: true
					}}
				/>

				<TextArea {...{
					className: `${classPrefix}-input`,
					labelText: 'Description',
					id: 'status',
					value: Context.state.customerRequest.description,
					disabled: true
				}}
				/>

				<div className={`${classPrefix}-request-images`}>
					<Lightbox
						images={imageData}
						thumbnailWidth='150px'
						thumbnailHeight='100px'
					/>
				</div>
			</div>
			<div className={`${classPrefix}-column`}>
				<div className={`${stepPrefix}-title title`}>Additional information</div>

				<TextInput
					{...{
						labelText: 'Location',
						id: `${classPrefix}-customer-location`,
						name: 'location',
						disabled: true,
						value: Context.state.customerRequest.customerLocation.city,
						required: true
					}}
				/>
				<TextInput
					{...{
						labelText: 'Contact',
						id: `${classPrefix}-customer-contact`,
						name: 'contact',
						disabled: true,
						value: Context.state.customerRequest.customerLocation.contactName,
						required: true
					}}
				/>
				<TextInput
					{...{
						labelText: 'Phone',
						id: `${classPrefix}-customer-phone`,
						name: 'phone',
						disabled: true,
						value: Context.state.customerRequest.customerLocation.contactPhoneNumber,
						required: true
					}}
				/>
			</div>
		</div>
	);
};

export { DetailsStep };
