import Button from '@carbon/ibm-security/lib/components/Button';
import { ProgressIndicator, ProgressStep } from 'carbon-components-react';
import React from 'react';
import { EButtonLabels } from 'utils';
import { EMode } from 'modules/Customers/interface';
import { EStatus } from 'modules/Requests/RequestInterfaces';
import { InformationFilled16 } from '@carbon/icons-react';

const PREFIX = 'sc--createflow';
export interface ICreateFlowContainerProps {
	createNewEntry?: () => void;
	currentStep: number;
	handleProgressStep: (step: number) => void;
	handleCreateFlowView: (closeView?: boolean) => void;
	handleSubmit: () => void;
	isLastStep: () => boolean;
	mode: string;
	renderMain: () => void;
	shouldEnableSave: boolean;
	stepsType: string[];
	title: string;
	updateCustomer?: () => void;
	updateContractor?: () => void;
	requestStatus?: string;
	newNotesHq?: boolean;
}

class CreateFlowContainer extends React.Component<ICreateFlowContainerProps> {
	handlePrimaryClick = () => {
		if (this.props.isLastStep()) {
			this.props.handleSubmit();
			return;
		}
		return this.props.handleProgressStep(this.props.currentStep);
	};

	isStepsDisabled = (index: number): boolean => {
		if (this.props.mode === EMode.EDIT) {
			return false;
		}

		return index > this.props.currentStep;
	};

	render() {
		const primaryButtonLabel: string = this.props.isLastStep()
			? EButtonLabels.SAVE : EButtonLabels.NEXT;

		return (
			<React.Fragment>
				<div className={`${PREFIX} bx--grid`}>
					<div className={`${PREFIX}-intro`}>
						<div>
							<h2 className={'cfc--main-title'} dangerouslySetInnerHTML={{ __html: this.props.title as string }} />
						</div>
						<div className={`${PREFIX}-button-wrapper`}>
							{this.props.requestStatus === EStatus.COMPLETED ? (
								<Button
									kind='primary'
									onClick={() => this.props.handleCreateFlowView()}>
									{EButtonLabels.CLOSE}
								</Button>
							) : (
								<React.Fragment>
									<Button
										kind='secondary'
										onClick={() => this.props.handleCreateFlowView()}
										disabled={false}>
										{EButtonLabels.CANCEL}
									</Button>
									<Button
										kind='primary'
										onClick={() => this.handlePrimaryClick()}
										disabled={this.props.shouldEnableSave}>
										{primaryButtonLabel}
									</Button>
								</React.Fragment>
							)}

						</div>
					</div>
					<div className={`${PREFIX}-progress-indicator-wrapper`}>
						<ProgressIndicator
							currentIndex={this.props.currentStep}
							className={`${PREFIX}-progress-indicator`}
							onChange={this.props.handleProgressStep}>
							{this.props.stepsType.map((label: string, index: number) => {
								let progressLabel: string | React.ReactNode = label;

								if (this.props.newNotesHq && label === 'History') {
									progressLabel = <span>{label}<InformationFilled16 /></span>
								}

								return (
									<ProgressStep
										index={index}
										className='sc--progress-step'
										current={this.props.currentStep === index}
										key={`${label}-${index}`}
										label={progressLabel}
										disabled={this.isStepsDisabled(index)}
									/>
								)
							})}
						</ProgressIndicator>
					</div>
					{this.props.renderMain()}
				</div>
			</React.Fragment>
		);
	}
}

export { CreateFlowContainer };
