import React from 'react';
import { IRequest, IRequestDate } from './RequestInterfaces';
import { ClickableTile, Tag } from '@carbon/ibm-security';
import RequestsContext from './RequestsContext';
import { ConvertTime } from 'utils';
import { Calendar16, Store16, InformationFilled16, Information16 } from '@carbon/icons-react';
import { requestPriorityMap, requestStatusColor } from './RequestUtilities';



export interface IRequestTileProps {
	customerRequest: IRequest;
}

const RequestTile: React.FunctionComponent<IRequestTileProps> = (props: IRequestTileProps) => {
	const Context = React.useContext(RequestsContext);
	const {
		customerRequest
	} = props;

	const handleTileClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		Context.getCustomerRequestDetails(customerRequest)
	}

	return (
		<React.Fragment>
			<ClickableTile
				href='#'
				handleClick={handleTileClick}
			>
				<div className={'tile-wrapper'}>
					<div>
						{customerRequest.newNotesHq && (
							<div className='new-note'>
								<InformationFilled16 />
							</div>
						)}
						<h4 className='tile-request-title'>{customerRequest.customerLocation.city}</h4>
						<Tag className='tile-request-priority' type={requestPriorityMap.get(customerRequest.priority)}>{customerRequest.priority}</Tag>
						<div className='tile-request-status'><strong>Status:</strong> <Tag type={requestStatusColor.get(customerRequest.status)} >{customerRequest.status}</Tag></div>
						<div><Information16 className={'tile-request-icon'} />{customerRequest.customerLocation.reference}</div>
						<div className='tile-request-date'><Calendar16 className={'tile-request-icon'} />{ConvertTime(customerRequest.requestStartDate as IRequestDate)}</div>
					</div>
					<div className='tile-request-description-wrapper'>
						<h5 className='tile-request-service-type'>Details</h5>
						<div><strong>Identifier:</strong>{customerRequest.docId}</div>
						<div><strong>Title:</strong>{customerRequest.title}</div>
						<div className='tile-request-description'><strong>Desciption</strong>{customerRequest.description}</div>
						<div><strong>Service(s) required:</strong>{customerRequest.serviceType}</div>
					</div>
				</div>
			</ClickableTile>
		</React.Fragment>
	)

}

export { RequestTile };