const RequestReducer = (prevState, action) => {

	switch (action.type) {
		case 'customerRequests':
			return {
				...prevState,
				customerRequests: action.value
			}
		case 'newNoteSeen':
			return {
				...prevState,
				customerRequest: {
					...prevState.customerRequest,
					newNotesHq: action.value
				}
			}
		case 'customerRequest':
			return {
				...prevState,
				customerRequest: action.value
			}
		case 'internalMessageDescription':
			return {
				...prevState,
				internalMessageDescription: action.value
			}
		case 'editCustomerRequest':
			return {
				...prevState,
				customerRequest: {
					...prevState.customerRequest,
					...action.value
				}
			}
		case 'requestInvoicing':
			return {
				...prevState,
				requestInvoicing: action.value
			}
		case 'requestState':
			return {
				...prevState,
				requestStatusColor: action.value.requestStatusColor,
				requestType: action.value.requestType
			}
		case 'requestContractors':
			return {
				...prevState,
				requestContractors: action.value
			}
		case 'contractorPriority':
			return {
				...prevState,
				contractorPriority: action.value
			}
		case 'requestFlowEnabled':
			return {
				...prevState,
				requestFlowEnabled: action.value
			}
		case 'customerRequestLogs':
			return {
				...prevState,
				customerRequestLogs: action.value
			}
		case 'privateMessage':
			return {
				...prevState,
				privateMessage: action.value
			}
		case 'initialState':
			return {
				...prevState,
				customerRequest: {},
				internalMessageDescription: '',
				privateMessage: '',
				contractorPriority: [],
				requestContractors: [],
				requestInvoicing: undefined,
				requestFlowEnabled: false,
				customerRequestLogs: []
			}
		default:
			return {
				...prevState
			}
	}
}

export {
	RequestReducer
}