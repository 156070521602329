import moment from 'moment';

/* eslint-disable no-useless-escape */
// Iterate object for empty string values
const isObjectEmpty = (obj): boolean => {
	for (const key in obj) {
		if (obj[ key ] !== '') {
			return false;
		}
	}
	return true;
};

// States map to convert firebase value to readable value in UI
// eg: firebase stores state as NSW when we read this back into the
// UI we map that value to the EStateValues enum. stateMapper(QLD) will return Queensland
enum EStateValues {
	NSW = 'New South Wales',
	VIC = 'Victoria',
	QLD = 'Queensland',
	SA = 'South Australia',
	WA = 'Western Australia',
	NT = 'Northern Territory',
	ACT = 'Australian Capital Territory'
}

enum EButtonTypes {
	Ghost = 'ghost',
	Primary = 'primary',
	Secondary = 'secondary',
	Tertiary = 'tertiary',
	Danger = 'danger'
}

enum EMessages {
	REQUIRED = 'This is a required field',
	EMAIL_INVALID = 'Check your email is formatted correctly',
	PHONE_NUMBER_INVALID = 'Check your contact phone number is correct'
}

enum EButtonLabels {
	ADD_MESSAGE = 'Add message',
	CANCEL = 'Cancel',
	CLOSE = 'Close',
	NEXT = 'Next',
	SAVE = 'Save',
}

enum ECustomerFlowLabels {
	DETAILS = 'Details',
	LOCATIONS = 'Location'
}
enum ERequestFlowLabels {
	DETAILS = 'Details',
	MANAGE = 'Manage'
}


const stateMapper = (stateValue: string) => {
	const labelMap: { [ key: string ]: string } = {
		[ EStateValues.NSW ]: EStateValues.NSW,
		[ EStateValues.VIC ]: EStateValues.VIC,
		[ EStateValues.QLD ]: EStateValues.QLD,
		[ EStateValues.SA ]: EStateValues.SA,
		[ EStateValues.WA ]: EStateValues.WA,
		[ EStateValues.NT ]: EStateValues.NT,
		[ EStateValues.ACT ]: EStateValues.ACT
	}

	return labelMap[ EStateValues[ stateValue ] ];
}

const RegexFormat = {
	abn: /^[0-9]{2}\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}$/,
	email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	phoneNumber: /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
	postCode: /^[0-9]{4}$/
}

export interface IDateRange {
	dateFrom: string;
	dateTo: string;
}

interface IStates {
	id: string;
	text: string;
}

const getStates: IStates[] = [
	{
		id: 'NSW',
		text: EStateValues.NSW
	},
	{
		id: 'VIC',
		text: EStateValues.VIC
	},
	{
		id: 'QLD',
		text: EStateValues.QLD
	},
	{
		id: 'SA',
		text: EStateValues.SA
	},
	{
		id: 'WA',
		text: EStateValues.WA
	},
	{
		id: 'NT',
		text: EStateValues.NT
	},
	{
		id: 'ACT',
		text: EStateValues.ACT
	}
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormatMoney = (amount: any, decimalCount = 2, decimal = '.', thousands = ','): string | void => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    console.log(e)
  }
};

/**
 *
 * @param month index of month
 * returns an IDateRange object with static formatted date strings mm/dd/yyyy
 */
 const GetMonthRange = (month: number): IDateRange => {
	const date = new Date();
	const firstDayOfMonth = new Date(date.getFullYear(), month, 1);
	const lastDayOfMonth = new Date(date.getFullYear(), month + 1, 0);

	const data: IDateRange = {
		dateFrom: moment(firstDayOfMonth).format('L'),
		dateTo: moment(lastDayOfMonth).format('L')
	}
	return data;
}

export { GetMonthRange, EButtonTypes, ERequestFlowLabels, ECustomerFlowLabels, EButtonLabels, RegexFormat, EMessages, isObjectEmpty, stateMapper, getStates, FormatMoney };
