import React from 'react';
import { withFirebase } from 'components/Firebase';
import { withRouter } from 'react-router-dom';

import Form from '@carbon/ibm-security/lib/components/Form';
import FormGroup from '@carbon/ibm-security/lib/components/FormGroup';
import TextInput from '@carbon/ibm-security/lib/components/TextInput';
import { Button, ButtonSkeleton } from '@carbon/ibm-security';


const ResetPassword: React.FunctionComponent<any> = ({ history, firebase }) => {

	const [ email, setEmail ] = React.useState<string>('');
	const [ error, setError ] = React.useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = React.useState<string>('');
	const [ successMessage, setSuccessMessage ] = React.useState<string>('');
	const [ inProgress, setInProgress ] = React.useState<boolean>(false);

	React.useEffect(() => {
		document.body.classList.add('sc--login-page');
	}, []);

	const handleLogin = () => {
		setInProgress(true);
		setErrorMessage('');
		firebase.doSendPasswordResetEmail(email)
			.then(() => {
				setSuccessMessage('Check your email for password reset instructions.')
			})
			.catch((err) => {
				setError(true);
				setErrorMessage(err.message)
			})
			.finally(() => {
				setInProgress(false);
			})
	}

	const isDisabled: boolean = email === '';

	return (
		<React.Fragment>
			<div className='bx--row sc--login-form-wrapper'>
				<div className='bx--col sc--login-marketing'></div>
				<div className='bx--col-md-3'>
					<div className='login-intro'>
						<p>{'Forgot password'}</p>
						<p>{'Enter your email address and we\'ll send you an email to reset your password.'}</p>
					</div>
					<Form
						onSubmit={handleLogin}
					>
						<FormGroup
							legendText=''
							invalid={error}
							message={error}
							messageText={errorMessage}
						>
							<TextInput
								id='sc--login-email'
								name='email'
								value={email}
								labelText='Email'
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setEmail(event.target.value),
										setErrorMessage('')
								}}
								placeholder='yourname@example.com'
								type='text'
							/>
							{successMessage && (
								<p className='sc--success-message'>{successMessage}</p>
							)}
						</FormGroup>
						{inProgress === true ? (
							<ButtonSkeleton />
						) : (
							<Button
								disabled={isDisabled}
								kind='primary'
								onClick={handleLogin}
								tabIndex={0}
								type='button'>
								{'Send email'}
							</Button>
						)}

					</Form>
				</div>
			</div>
		</React.Fragment>
	)
}

export default withRouter(withFirebase(ResetPassword));
