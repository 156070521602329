import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID
};

class Firebase {
	auth: app.auth.Auth;
	db: app.firestore.Firestore;
	storage: app.storage.Storage;
	functions: app.functions.Functions;
	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.db = app.firestore();
		this.storage = app.storage();
		this.functions = app.app().functions('asia-northeast1');
	}

	enums = () => this.db.collection('enums');
	batch = () => this.db.batch();

	// Customer related APIs for Firebase
	customers = () => this.db.collection('customers');
	customerLocations = () => this.db.collection('customerLocations');
	customerUsers = () => this.db.collection('customerUsers');

	// Contractors
	contractors = () => this.db.collection('contractors');
	customerContractors = () => this.db.collection('customerContractors');

	// Request related Apis
	requests = () => this.db.collection('requests');
	requestInvoicing = () => this.db.collection('requestInvoicing');
	customerLocationContractors = () => this.db.collection('customerLocationContractors');
	requestContractorPriority = () => this.db.collection('requestContractorPriority');
	// Manage related Apis
	createCustomerUser = () => this.db.collection('createCustomerUser')
	updateCustomerUser = () => this.db.collection('updateCustomerUser')

	isAdmin = (): Promise<boolean> | null | any => this.auth.currentUser && this.auth.currentUser.getIdTokenResult()
		.then((idTokenResult) => {
			if (idTokenResult.claims) {
				return true;
			}
			return false;
		})
		.catch((err) => {
			console.log('Error in Firebase', err);
			return false;
		})

	getCurrentUserClaims = () => this.auth.currentUser && this.auth.currentUser.getIdTokenResult()
		.then((idTokenResult) => {
			const { role, customerRef } = idTokenResult.claims;
			const claims = { role, customerRef };
			return claims;
		})
		.catch((error) => {
			return error;
		})

	// Firebase API
	doSignInWithEmailAndPassword = async (email: string, password: string) => {

		try {
			// await this.auth.setPersistence('local');
			return await this.auth.signInWithEmailAndPassword(email, password)
		} catch (error) {
			return Promise.reject(error);
		}

	};

	doSendPasswordResetEmail = (email: string) => {
		return this.auth.sendPasswordResetEmail(email);
	}

	doSignOut = () => {
		return this.auth.signOut();
	}

	signInWithCredentials = (credentials: any) => {
		return this.auth.signInWithCredential(credentials);
	}
}

export default Firebase;
