import firebase from 'firebase';
import { ESwitch, EStatus, ICustomerLocationContractor, ERequestRadioLabels, EPriorityList, EPriorityTypes, IRequestLog, ERequestVisibility, ELogType, EType } from './RequestInterfaces';

enum EContentSwitcherIndex {
	Pending = 0,
	Open = 1,
	Closed = 2
}

const getStatusTypes = (status: string): string[] => {
	const selectedStatus = new Map([
		[ `${ESwitch.OPEN}`, `${EStatus.AWAITING_ACCEPTANCE}, ${EStatus.ACCEPTED}, ${EStatus.CONTRACTOR_COMPLETED}` ],
		[ `${ESwitch.PENDING}`, `${EStatus.PENDING_APPROVAL}, ${EStatus.REQUEST_FOR_INFORMATION}, ${EStatus.AWAITING_QUOTE}, ${EStatus.PENDING_QUOTE_APPROVAL}` ],
		[ `${ESwitch.CLOSED}`, `${EStatus.COMPLETED}, ${EStatus.DECLINED}, ${EStatus.CANCELLED}` ]
	]);

	const statusArray = selectedStatus.get(status) as string;
	if (statusArray) {
		return statusArray.split(', ')
	}
	return [];
};

const setStatusTagColor = (status: string): string => {
	const selectedStatus = new Map([
		[ `${ESwitch.OPEN}`, 'teal' ],
		[ `${ESwitch.PENDING}`, 'red' ],
		[ `${ESwitch.CLOSED}`, 'green' ]
	]);

	return selectedStatus.get(status) as string;

}

const contractorPriorityOrder = (contractorArray: ICustomerLocationContractor[]): string[] => {
	const contractorPriorityList: string[] = contractorArray.map((contractor: ICustomerLocationContractor) => {
		return contractor.contractorId;
	});

	return contractorPriorityList;
}

const requestStatusColor = new Map([
	[EStatus.ACCEPTED, EPriorityTypes.Teal],
	[EStatus.AWAITING_ACCEPTANCE, EPriorityTypes.Blue],
	[EStatus.AWAITING_QUOTE, EPriorityTypes.Blue],
	[EStatus.CANCELLED, EPriorityTypes.Magenta],
	[EStatus.COMPLETED, EPriorityTypes.Green],
	[EStatus.CONTRACTOR_COMPLETED, EPriorityTypes.Green],
	[EStatus.DECLINED, EPriorityTypes.Red],
	[EStatus.PENDING_APPROVAL, EPriorityTypes.Purple],
	[EStatus.PENDING_QUOTE_APPROVAL, EPriorityTypes.Green],
	[EStatus.REQUEST_FOR_INFORMATION, EPriorityTypes.Red]
])

const requestPriorityMap = new Map(
	[
		[EPriorityList.None, EPriorityTypes.Blue],
		[EPriorityList.Low, EPriorityTypes.Blue],
		[EPriorityList.Medium, EPriorityTypes.Magenta],
		[EPriorityList.High, EPriorityTypes.Red],
		[EPriorityList.Urgent, EPriorityTypes.Red]
	]
);

const requestStatusMap = new Map();
requestStatusMap
	.set(ERequestRadioLabels.Accept, { status: EStatus.ACCEPTED, desc: 'Contractor will be notified the quote amount submitted has been accepted.', filterFor: EType.Contractor })
	.set(ERequestRadioLabels.AssignContractor, { status: EStatus.AWAITING_ACCEPTANCE, desc: 'Awaiting for the contractor to accept the maintenance request.', filterFor: EType.Manager })
	.set(ERequestRadioLabels.Cancel, { status: EStatus.CANCELLED, desc: 'The store manager will be notified and the request closed.', filterFor: EType.Manager })
	.set(ERequestRadioLabels.RequestForMoreInformation, { status: EStatus.REQUEST_FOR_INFORMATION, desc: 'Insufficient information was provided. The store manager will be notified and will need to provide further details.', filterFor: EType.Manager })
	// .set(ERequestRadioLabels.Decline, { status: EStatus.DECLINED, desc: 'Decline the contractors submitted quote.', filterFor: EType.Contractor })


const requestStatusChangeMap = new Map();
requestStatusChangeMap
	.set(EStatus.ACCEPTED, 'The request has been accepted.')
	.set(EStatus.AWAITING_ACCEPTANCE, 'The request is awaiting the contractor\'s acceptance.')
	.set(EStatus.AWAITING_QUOTE, 'HQ is awaiting a quote from the contractor.')
	.set(EStatus.CANCELLED, 'The request has been cancelled.')
	.set(EStatus.COMPLETED, 'The request has been completed.')
	.set(EStatus.CONTRACTOR_COMPLETED, 'The contractor has completed the request.')
	.set(EStatus.DECLINED, 'The request has been declined by the contractor.')
	.set(EStatus.PENDING_APPROVAL, 'The request is pending approval.')
	.set(EStatus.PENDING_QUOTE_APPROVAL, 'Contractor has submitted a quote and is ready to be reviewed.')
	.set(EStatus.REQUEST_FOR_INFORMATION, 'Further information needs to be provided')

const generateLogEntry = (requestDescription: string, customerLocId: string, status: string, visibility: ERequestVisibility, displayName?: string ): IRequestLog => {
	const defaultLogEntry: IRequestLog = {
		addedDate: firebase.firestore.Timestamp.fromDate(new Date()),
		description: requestStatusChangeMap.get(status),
		displayName: displayName || 'Admin (HQ)',
		type: ELogType.Status,
		uid: customerLocId,
		visibility: visibility || ERequestVisibility.Public
	};

	if(requestDescription.length){
		defaultLogEntry.description = requestDescription,
		defaultLogEntry.type = ELogType.Comment
	}

	return defaultLogEntry;

}

export {
	EContentSwitcherIndex,
	requestStatusColor,
	requestPriorityMap,
	requestStatusMap,
	requestStatusChangeMap,
	contractorPriorityOrder,
	getStatusTypes,
	setStatusTagColor,
	generateLogEntry
}