import { Header, HeaderName } from 'carbon-components-react/lib/components/UIShell';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CustomerContainer } from './modules/Customers/CustomerContainer';
import ManageContainer from './modules/Manage/ManageContainer';
import { RequestsContainer } from './modules/Requests/RequestsContainer'
import LoginContainer from './modules/Login/LoginContainer';
import DashboardContainer from './modules/Dashboard/DashboardContainer'
import ForgottenPassword from 'modules/Login/ForgottenPassword';
import SignoutContainer from 'modules/Signout/SIgnoutContainer';
import * as ROUTES from './utils/routes';
import { AuthProvider } from './components/Auth';
import { withFirebase } from './components/Firebase';
import PrivateRoute from './components/Router/PrivateRoute';
import { HeaderNavWrapper } from './components/HeaderNavWrapper';
// import { NoMatch } from './components/Router';
import ResetPassword from 'modules/Login/ResetPassword';
import ActivityContainer from 'modules/Activity/ActivityContainer';

const App: React.FunctionComponent = () => {
	return (
		<AuthProvider>
			<Router>
				<Header className={'sc--header-wrapper'} aria-label='Simplyclik Admin'>
					<HeaderName className={'sc--header-link'} href={`${ROUTES.ADMIN_DASHBOARD}`}>{''}</HeaderName>
					<HeaderNavWrapper />
				</Header>
				<div className={'sc--main-wrapper'}>
					<Switch>
						<Route exact path={'/'} component={LoginContainer} />
						<Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
						<Route path={ROUTES.FORGOTTEN_PASSWORD} component={ForgottenPassword} />
						<Route path={ROUTES.SIGN_OUT} component={SignoutContainer} />
						<PrivateRoute exact path={ROUTES.ADMIN_DASHBOARD} component={DashboardContainer} />
						<PrivateRoute exact path={ROUTES.ADMIN_ACTIVITY} component={ActivityContainer} />
						<PrivateRoute exact path={ROUTES.ADMIN_REQUESTS} component={RequestsContainer} />
						<PrivateRoute exact path={ROUTES.ADMIN_CUSTOMERS} component={CustomerContainer} />
						<PrivateRoute exact path={ROUTES.ADMIN_MANAGE} component={ManageContainer} />
						{/* <Redirect to='/login' /> */}
					</Switch>
				</div>
			</Router>
		</AuthProvider>
	);
}

export default withFirebase(App);
