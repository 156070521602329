import React from 'react';
import { FirebaseContext } from 'components/Firebase';
import { withRouter, Link } from 'react-router-dom';
import { Button, ButtonSkeleton, Form, FormGroup, TextInput } from '@carbon/ibm-security';
import * as ROUTES from '../../utils/routes';

const ForgottenPassword: React.FunctionComponent = () => {

	const FirebaseCTX = React.useContext(FirebaseContext);

	const [ email, setEmail ] = React.useState<string>('');
	const [ error, setError ] = React.useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = React.useState<string>('');
	const [ successMessage, setSuccessMessage ] = React.useState<string>('');
	const [ inProgress, setInProgress ] = React.useState<boolean>(false);

	React.useEffect(() => {
		document.body.classList.add('sc--login-page');
		return () => {
			document.body.classList.remove('sc--login-page')
		}
	}, []);

	const handleLogin = () => {
		setInProgress(true);
		setErrorMessage('');
		if (FirebaseCTX) {
			FirebaseCTX.doSendPasswordResetEmail(email)
				.then(() => {
					setSuccessMessage('Check your email for password reset instructions.')
				})
				.catch((err) => {
					setError(true);
					setErrorMessage(err.message)
				})
				.finally(() => {
					setInProgress(false);
				})
		}
	}

	const isDisabled: boolean = email === '';

	return (
		<React.Fragment>
			<div className='bx--row sc--login-form-wrapper'>
				<div className='bx--col sc--login-marketing'></div>
				<div className='bx--col-md-3'>
					<div className='login-intro'>
						<p>{'Forgotten password?'}</p>
						<p>{'Enter your email address and we\'ll send you an email to reset your password.'}</p>
					</div>
					<Form
						onSubmit={handleLogin}
					>
						<FormGroup
							legendText=''
							invalid={error}
							message={error}
							messageText={errorMessage}
						>
							<TextInput
								id='sc--login-email'
								name='email'
								value={email}
								labelText='Email'
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setEmail(event.target.value),
										setErrorMessage('')
								}}
								placeholder='yourname@example.com'
								type='text'
							/>
							{successMessage && (
								<p className='sc--success-message'>{successMessage}</p>
							)}
						</FormGroup>
						{inProgress === true ? (
							<ButtonSkeleton />
						) : (
							<React.Fragment>
								<Button
									disabled={isDisabled}
									kind='primary'
									onClick={handleLogin}
									tabIndex={0}
									type='button'>
									{'Send email'}
								</Button>
							</React.Fragment>
						)}
					</Form>
					<Link className={'login-link'} to={ROUTES.LANDING}>Back to login?</Link>
				</div>
			</div>
		</React.Fragment>
	)
}

export default withRouter(ForgottenPassword);
