const CustomerReducer = (prevState, action) => {

	switch (action.type) {
		case 'customers':
			return {
				...prevState,
				customers: action.value
			}
		case 'customer':
			if(action.isNested){
				return {
				...prevState,
					customer: {
						...prevState.customer,
						address: {
							...prevState.customer.address,
							...action.value
						}
					}
				}
			} else {
			return {
				...prevState,
				customer: {
					...prevState.customer,
					...action.value
				}
			}
		}
		case 'addEditViewEnabled':
			return {
				...prevState,
				addEditViewEnabled: action.value.addEditViewEnabled,
				mode: action.value.mode
			}
		case 'inputValidation':

				return {
					...prevState,
					inputValidation: {
						...prevState.inputValidation,
							[action.step.toLowerCase()]: {
								...prevState.inputValidation[action.step.toLowerCase()],
								...action.value
							}
						}
					}
				// }
		default:
			return {
				prevState
			}
	}
}

export {
	CustomerReducer
}