
/* eslint-disable no-undef */
interface IManageContextProps {
	state: IManageReducerInitalState;
	dispatch: ({ type }: { type: string; value?: any }) => void;
	isLoading: boolean;
}
interface ICustomerDetails {
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	id: string;
	role: string;
}

interface ICustomerUserDetails {
	name: string;
	email: string;
	role: string;
	phone?: string;
	id?: string;
}

interface ICustomerUsersUid {
	uid: string;
	// users: ICustomerUserDetails[];
}

interface ILoader {
	message: string;
	isLoading: boolean;
}

interface IManageReducerInitalState {
	addEditViewEnabled: boolean;
	editableCustomerValues?: ICustomerDetails;
	manageCustomerUsers: ICustomerUserDetails[];
	manageCustomerUser: ICustomerUserDetails;
	mode: string;
	tableDataLoaded: boolean;
}

interface IError {
	message: string;
	isActive: boolean;
}

enum EMode {
	EDIT = 'Edit',
	ADD = 'Add'
}

enum ERoles {
	OPERATOR = 'Operator',
	USER = 'User',
	MANAGER = 'Manager'
}

export {
	IError,
	IManageContextProps,
	ICustomerUserDetails,
	EMode,
	ERoles,
	IManageReducerInitalState,
	ICustomerDetails
}
