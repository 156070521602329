import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@carbon/ibm-security';
import { Login24 } from '@carbon/icons-react';


const SignoutContainer: React.FunctionComponent = () => {

	React.useEffect(() => {
		document.body.classList.add('sc--sign-out-page');
		return () => {
			document.body.classList.remove('sc--sign-out-page')
		}
	}, []);

	return (
		<React.Fragment>
			<div className='bx--row sc--sign-out-form-wrapper'>
				<div className='bx--col sc--sign-out-image-wrapper'>
					<img src='/images/head-office.svg' alt='Head office image' />
				</div>
				<div className='bx--col-md-3'>
					<div className='sc--sign-out-main'>
						<p>{'You have successfully signed out.'}<span>{'Thank you and have a great day.'}</span></p>
						<Button renderIcon={Login24} href={'/'}> {'Login back in'}</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default withRouter(SignoutContainer);
