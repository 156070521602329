/* eslint-disable no-useless-escape */
import * as React from 'react';
import { Button, TextArea, Tag } from '@carbon/ibm-security';
import RequestsContext from '../RequestsContext';
import { ELogType, IRequestLog } from '../RequestInterfaces';
import moment from 'moment';
import { requestStatusChangeMap, requestStatusColor } from '../RequestUtilities';
import { EButtonLabels, EButtonTypes } from 'utils';
import { Send24 } from '@carbon/icons-react';


const classPrefix = 'sc--request-flow';

const HistoryStep: React.FunctionComponent = (): JSX.Element => {
	const { state, dispatch, getCustomerRequestNotes, logPrivateMessage, newNoteHqSeen } = React.useContext(RequestsContext);

	const [ isButtonDisabled, setIsButtonnDisabled ] = React.useState<boolean>(true);
	const [ newNoteViewed, setNewNoteViewed ] = React.useState<boolean>(false);
	React.useEffect(() => {

		const newNote: boolean | undefined = state.customerRequest.newNotesHq;

		if (state) {
			getCustomerRequestNotes(state.customerRequest.docId);
		}
		if (newNote) {
			dispatch({
				type: 'newNoteSeen',
				value: false
			});
			setNewNoteViewed(true);
		}

	}, []);

	React.useEffect(() => {
		if (newNoteViewed) {
			newNoteHqSeen();
		}
	}, [ newNoteViewed ])

	React.useEffect(() => {
		if (state) {
			setIsButtonnDisabled(state.privateMessage === '');
		}
	}, [ state, setIsButtonnDisabled ]);

	const setTagColor = React.useCallback((statusDescription: string) => {
		let tagColor = ''
		for (const [ key, value ] of requestStatusChangeMap.entries()) {
			if (value === statusDescription) {
				tagColor = requestStatusColor.get(key) as string;
				break;
			}
			continue;
		}
		return tagColor;
	}, []);

	const handleMessageTextArea = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({
			type: 'privateMessage',
			value: event.target.value
		});

		setIsButtonnDisabled(event.target.value === '');

	}, [ state, dispatch, setIsButtonnDisabled ]);

	return (
		<div className={`${classPrefix}-step manage`}>
			<div className={`${classPrefix}-column`}>
				<div className={'sc--title title'}>
					{'History log'}
					<span>{'The history log contains a track record of request information and status.'}</span>
				</div>
				<div className='sc--request-history-wrapper'>
					<div className='inner-wrapper'>
						{state.customerRequestLogs && state.customerRequestLogs.map((logEntry: IRequestLog, index: number): JSX.Element => {
							return (
								<div className={'request-history-textarea-container'} key={`request-textarea-${index}`}>
									{logEntry.type === ELogType.Comment ? (
										<TextArea
											labelText={`Posted by: ${logEntry.displayName}`}
											helperText={moment(logEntry.addedDate.seconds * 1000).format('LLLL')}
											disabled={true}
											value={logEntry.description}
										/>
									) : (
										<React.Fragment>
											<label className='bx--label bx--label--disabled'>Request status updated.</label>
											<div className='bx--form__helper-text bx--form__helper-text--disabled'>{moment(logEntry.addedDate.seconds * 1000).format('LLLL')}</div>
											<Tag type={setTagColor(logEntry.description as string)}>{logEntry.description}</Tag>
										</React.Fragment>
									)}
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<div className={`${classPrefix}-column`}>
				<div className={'sc--title title'}>
					{'Private messaging'}
				</div>
				<div className='sc--request-internal-messaging'>
					<TextArea
						labelText={'Message'}
						placeholder={'Type here'}
						helperText={'All messages submitted in this field will be only viewable by HQ staff'}
						onChange={handleMessageTextArea}
						value={state.privateMessage}
					/>
					<Button
						kind={EButtonTypes.Ghost}
						renderIcon={Send24}
						disabled={isButtonDisabled}
						onClick={logPrivateMessage} >
						{EButtonLabels.ADD_MESSAGE}
					</Button>
				</div>
			</div>
		</div>

	)
};

export { HistoryStep };
