import React from "react";
import { Add16, Edit16 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  DataTableSkeleton,
  Pagination,
} from "@carbon/ibm-security";
import { ETableHeaderMap } from "../Customers/interface";
import { ICustomerUserDetails, EMode, ERoles } from "./ManageInterfaces";
import {
  getItemRangeText,
  getPageRangeText,
  ITableHeaderItem,
  IRow,
  ICell,
} from "utils/table";
import { PageSizes } from "utils/table";
import ManageContext from "./ManageContext";
import { AuthContext } from "components/Auth";

const defaultCustomerUser: ICustomerUserDetails = {
  name: "",
  email: "",
  role: "",
  phone: "",
};

const {
  Table,
  TableContainer,
  TableHead,
  TableHeader,
  TableBody,
  TableCell,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;
const headers: ITableHeaderItem[] = [
  {
    header: ETableHeaderMap.NAME,
    key: "name",
  },
  {
    header: ETableHeaderMap.ROLE,
    key: "role",
  },
  {
    header: ETableHeaderMap.PHONE,
    key: "phone",
  },
  {
    header: ETableHeaderMap.EMAIL,
    key: "email",
  },
  {
    header: "",
    key: ETableHeaderMap.EDIT,
  },
];

const ManageTable: React.FunctionComponent = () => {
  const Context = React.useContext(ManageContext);
  const AuthCtx = React.useContext(AuthContext);

  const [rows, setRows] = React.useState(Context.state.manageCustomerUsers);
  const [pagination, setPagination] = React.useState({
    pageSize: PageSizes.pageSizes[0],
    page: 1,
  });

  React.useEffect(() => {
    if (Context.state.manageCustomerUsers) {
      setRows(Context.state.manageCustomerUsers);
    }
  }, [Context]);

  const addEditCustomer = React.useCallback(
    (mode: string, id?: string) => {
      let customeruser = {} as ICustomerUserDetails | undefined;
      if (id) {
        customeruser = rows.find((customerObj: ICustomerUserDetails) => {
          return customerObj.id === id;
        });
      } else {
        customeruser = defaultCustomerUser;
      }

      if (customeruser) {
        // Destructure customer to create subset customer
        // const customerSubset = (({ name, email, phone, role, docRef }) => ({ contactName, contactEmail, contactPhoneNumber, role, id	 }))(customer);
        Context.dispatch({
          type: "editableCustomerUser",
          value: customeruser,
        });
        Context.dispatch({
          type: "addEditViewEnabled",
          value: {
            addEditViewEnabled: true,
            mode: mode,
          },
        });
      }
    },
    [Context.dispatch, rows]
  );

  // const filterRows = React.useCallback((rows: ICustomer[]) => {
  // 	const startIndex =
  // 		(pagination.page - 1) * pagination.pageSize;
  // 	const endIndex =
  // 		pagination.page * pagination.pageSize;
  // 	return rows.filter((row: ICustomer, index: number) => {
  // 		if (index >= startIndex && index < endIndex) return true;
  // 		return false;
  // 	});
  // }, [pagination.page, rows]);

  const handlePageChange = React.useCallback(
    (pageChange: { page: number; pageSize: number }): void => {
      setPagination({ ...pageChange });
    },
    [setPagination]
  );

  return (
    <React.Fragment>
      <div className={"sc--table-wrapper bx--data-table--zebra"}>
        <DataTable
          rows={rows}
          headers={headers}
          isSelectable={false}
          isSortable={false}
          render={({ rows, headers, getHeaderProps }) => (
            <TableContainer>
              <TableToolbar>
                <TableToolbarContent>
                  <TableToolbarSearch
                    className="sc--search-input"
                    persistent={true}
                    placeHolderText={"Filter by name"}
                  />
                  <Button
                    kind={"primary"}
                    renderIcon={Add16}
                    disabled={AuthCtx.claims.role === ERoles.USER}
                    iconDescription={"Add new user"}
                    onClick={() => addEditCustomer(EMode.ADD)}
                  >
                    Add new user
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              {Context.state.tableDataLoaded ? (
                <React.Fragment>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headers.map((header: ITableHeaderItem) => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: IRow) => (
                        <TableRow key={row.id} className={"sc--table-row"}>
                          {row.cells.map((cell: ICell) => {
                            if (cell.info.header === "Edit") {
                              return (
                                <TableCell key={cell.id}>
                                  <Button
                                    className={"sc--edit-table-icon"}
                                    kind={"ghost"}
                                    renderIcon={Edit16}
                                    iconDescription={"Edit user"}
                                    onClick={() => {
                                      addEditCustomer(EMode.EDIT, row.id);
                                    }}
                                  />
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value || "-"}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Pagination
                    backwardText="Previous page"
                    disabled={false}
                    forwardText="Next page"
                    isLastPage={false}
                    itemRangeText={getItemRangeText}
                    itemsPerPageText="Items per page:"
                    onChange={handlePageChange}
                    page={pagination.page}
                    pageInputDisabled
                    pageNumberText="Page Number"
                    pageRangeText={getPageRangeText}
                    pageSize={pagination.pageSize}
                    pageSizes={PageSizes.pageSizes}
                    totalItems={rows.length}
                  />
                </React.Fragment>
              ) : (
                <DataTableSkeleton />
              )}
            </TableContainer>
          )}
        />
      </div>
    </React.Fragment>
  );
};

export { ManageTable };
