enum EMonths {
	January = 1,
	February = 2,
	March = 3,
	April = 4,
	May = 5,
	June = 6,
	July = 7,
	August = 8,
	September = 9,
	October = 10,
	November = 11,
	December = 12,
}

const getDaysOfMonth = (month: number): number => {
	const dateInstance = new Date();
	const currentYear = dateInstance.getFullYear();
	return new Date(currentYear, month, 0).getDate();
}

export { EMonths, getDaysOfMonth }
