export const LANDING = '/';
export const LOGIN = '/login';
export const SIGN_OUT = '/signed-out';
export const RESET_PASSWORD = '/reset-password';
export const FORGOTTEN_PASSWORD = '/forgotten-password';
export const ADMIN_ACTIVITY = '/activity'
export const ADMIN_CUSTOMERS = '/customers';
export const ADMIN_DASHBOARD = '/dashboard';
export const ADMIN_MANAGE = '/manage';
export const ADMIN_REQUESTS = '/requests';

